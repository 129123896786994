
import * as aroraProviderRuntime$_6kxOe_dvW2IZ75O4JWQ9thJ2dSZDPLVwgVzQW7ncdNY from 'D:/deploy/UploadHidden/20250404-P903.327/arora-front/satellite/build-rest-4d9205b6-09f5-4ea7-801b-44777f2ce220/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$_6kxOe_dvW2IZ75O4JWQ9thJ2dSZDPLVwgVzQW7ncdNY, defaults: {} }
}
        